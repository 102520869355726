<div class="mt-overlay-wrap" data-cy="gated-overlay-wrap">
  <div class="mt-overlay">
    <ng-container *ngIf="isHover$ | async as isHover">
      <mt-gated-content-icon
        *ngIf="isSection && isHover"
        [isSection]="isSection"
      ></mt-gated-content-icon>
    </ng-container>
  </div>
</div>
